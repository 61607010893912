<template>
  <div class="app-error align-content-stretch d-flex flex-wrap">
    <div class="app-error-info">
      <h5>
        {{
          $t(
            "Components.404.Opps",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h5>
      <span>{{
        $t("Components.404.Title", {}, { locale: this.$store.state.activeLang })
      }}</span>
      <router-link :to="$appHomeUrl" class="btn btn-primary">{{
        $t(
          "BaseModelFields.ReturnHome",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</router-link>
    </div>
    <div class="app-error-background"></div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
export default {
  name: "404",
  components: {},
  mounted() {
    this.$root.changeDocumentTitle(
      this.$t("Components.404.Title"),
      {},
      { locale: this.$store.state.activeLang }
    );
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      // store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      // store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    return {};
  },
};
</script>
